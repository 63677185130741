import { template as template_5013aa6d61dc4bcfb7894f5e2b6f7be7 } from "@ember/template-compiler";
const FKLabel = template_5013aa6d61dc4bcfb7894f5e2b6f7be7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
