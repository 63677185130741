import { template as template_6d7e7844e0f84599a30be073f2b6e87d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6d7e7844e0f84599a30be073f2b6e87d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
