import { template as template_f75d82af2e4346cd89b3876e5bea3873 } from "@ember/template-compiler";
const WelcomeHeader = template_f75d82af2e4346cd89b3876e5bea3873(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
